/*jshint esversion: 6*/

var Apex;
var isApp=false;
var global_base_path;

export function aiModel(id_station, anno,lat,lon,base_path,ApexC,is_app) {
  if (is_app) {
    isApp=is_app;
  }

  if (typeof base_path!='undefined') {
    global_base_path=base_path;
  }

  if (typeof Apex=='undefined') {
    if (ApexC) {
      Apex=ApexC;
    }
    else {
      Apex=ApexCharts;
    }
  }

  var aeco_url='https://aeco-staging.aedit.it/api/aeco/';

  var request={};

  if (typeof anno!='undefined' && anno!=null) {
    request.anno=anno;
  }
  else{
    request.anno=new Date().getFullYear();
  }

  if (id_station==null && typeof lat!= 'undefined') {
    request.lat=lat;
    request.lon=lon;
  }

  var model_path='';

  if (typeof aedita_model_api_path=='undefined') {

    model_path=global_base_path+'mod_aedita_model';
    // aedita_model_api_path=base_path+'mod_aedita_model';
  }
  else {
    model_path=aedita_model_api_path ;
  }

  jQuery('#aedita_model').html(`<div style="text-align:center;margin-top:60px;"><img src='https://mir-s3-cdn-cf.behance.net/project_modules/disp/04de2e31234507.564a1d23645bf.gif'></img></div>`);

  jQuery.ajax({
    type: 'GET',
    url: model_path+"/get_weather/station/"+id_station,
    dataType: "json",
    data:request,
    contentType: "application/json; charset=utf-8",
    success: function(weather){

      var weather_data=[
        {"datetime": "2020-01-01", "tmin": 3.1, "tmax": 12, "tavg": 6.7, "psum":0, "radsum":300},
        {"datetime": "2020-01-02", "tmin": 3.4, "tmax": 12.2, "tavg": 7.2, "psum":11, "radsum":310.2},
        {"datetime": "2020-01-03", "tmin": 3.7, "tmax": 12.12, "tavg": 7.0, "psum":0, "radsum":320.1},
        {"datetime": "2020-01-04", "tmin": 3.7, "tmax": 12.12, "tavg": 7.0, "psum":0, "radsum":320.1},
        {"datetime": "2020-01-05", "tmin": 3.7, "tmax": 12.12, "tavg": 7.0, "psum":0, "radsum":320.1},
        {"datetime": "2020-01-06", "tmin": 3.7, "tmax": 12.12, "tavg": 7.0, "psum":0, "radsum":320.1},
        {"datetime": "2020-01-07", "tmin": 3.7, "tmax": 12.12, "tavg": 7.0, "psum":0, "radsum":320.1},
        {"datetime": "2020-01-08", "tmin": 3.7, "tmax": 12.12, "tavg": 7.0, "psum":0, "radsum":320.1},
        {"datetime": "2020-01-09", "tmin": 3.7, "tmax": 12.12, "tavg": 7.0, "psum":0, "radsum":320.1},
      ];

      console.log(weather_data);


      try {
        var weather_data_ok=[];
        if (weather.weather && weather.weather.ok && weather.weather.rowCount>0) {
          weather_data=weather.weather.data;

          var currentYear=new Date().getFullYear();


          jQuery.each(weather_data,function(k,v){
            weather_data_ok[k]={};
            jQuery.each(v,function(i,d){
              if (i!='datetime') {
                d=parseFloat(d);
                weather_data_ok[k][i]=parseFloat(d);
              }
              else {
                weather_data_ok[k][i]=d;
              }
            });
          });

          weather_data=weather_data_ok;
        }
      }
      catch (e) {
        console.log("ERRORE!!!!!!");

        console.log(e);
      }

      var coords={
        "latitude": 43.77,
        "longitude" : 10.38
      };

      var climate=[{}];
      if (weather.climate && weather.climate.data) {

        var climate_ok= weather.climate.data;
        jQuery.each(climate_ok,function(k,v){
          climate_ok[k]=(v);
          jQuery.each(climate_ok[k],function(k2,v2){
            climate_ok[k][k2]=parseFloat(v2);
          });
        });

        climate=climate_ok;
      }
      else {
        climate=[{
          "month": 1,
          "tmin": "3.5",
          "tmax": "10.4",
          "tavg": "6.9",
          "psum": "82"
        },
        {
          "month": 2,
          "tmin": "3.6",
          "tmax": "11.3",
          "tavg": "7.4",
          "psum": "84"
        },
        {
          "month": 3,
          "tmin": "6.1",
          "tmax": "14.3",
          "tavg": "10.3",
          "psum": "82"
        },
        {
          "month": 4,
          "tmin": "9.1",
          "tmax": "17.5",
          "tavg": "13.5",
          "psum": "98"
        },
        {
          "month": 5,
          "tmin": "12.9",
          "tmax": "21.2",
          "tavg": "17.3",
          "psum": "78"
        },
        {
          "month": 6,
          "tmin": "17.2",
          "tmax": "25.4",
          "tavg": "21.5",
          "psum": "57"
        },
        {
          "month": 7,
          "tmin": "19.7",
          "tmax": "28",
          "tavg": "24.1",
          "psum": "34"
        },
        {
          "month": 8,
          "tmin": "19.9",
          "tmax": "28.3",
          "tavg": "24.2",
          "psum": "43"
        },
        {
          "month": 9,
          "tmin": "16.3",
          "tmax": "24.3",
          "tavg": "20.4",
          "psum": "111"
        },
        {
          "month": 10,
          "tmin": "12.9",
          "tmax": "20",
          "tavg": "16.4",
          "psum": "150"
        },
        {
          "month": 11,
          "tmin": "8.7",
          "tmax": "15",
          "tavg": "11.8",
          "psum": "162"
        },
        {
          "month": 12,
          "tmin": "4.8",
          "tmax": "11.4",
          "tavg": "8",
          "psum": "108"
        }
      ];
      }

      if (weather.station && weather.station.rowCount>0) {
        coords={
          "latitude": parseFloat(weather.station.data[0].lat),
          "longitude" : parseFloat(weather.station.data[0].lon),
          "monthly":climate
        };
      }

      var payload={
        "weather":weather_data,
        "climate": coords,
        "farmer":{
          "perc_inf_olivefly":76
        }
      };

      var s={};
      if (!isApp) {
        console.log("ID_STATION:"+id_station);

        s=jQuery.jStorage.get('ai_'+anno+'_'+id_station);
      }
      else {
        // if (! $.jStorage.get('ai_app')) {
        //   $.jStorage.set('ai_app',settings);
        // }
        s=$.jStorage.get('ai_app');
      }
      // debugger

      if (s && s.data_ovideposizione) {
        payload.farmer.olive_fly_monitoring= {
          "log":
          [
            {  "datetime": s.data_ovideposizione, "olive_inf":1}
          ]
        };
      }

      var urladdr = aeco_url+'dss_olivefly_complete';
      jQuery.ajax({
        type: 'POST',
        async:false,
        url: urladdr,
        dataType: "json",
        data: JSON.stringify(payload),
        contentType: "application/json; charset=utf-8",
        success: function(model_output){
          console.log("RES",model_output);
          if (model_output.ok && model_output.results.values.length>0) {
            var html='';
            // html +='<div id="model_summary" style="margin: auto; width: 35%;  padding: 10px;"></div>';
            // html +='<div id="model_chart"></div>';
            // html +='<div id="model_table"></div>';

            if (model_output.result_summary.last_record.phase_corrected==10) {
              var classRow='row';
              if (isApp) {
                classRow='';
              }

              html +='<div id="model_summary" class="'+classRow+'">';

                if (isApp) {
                  html +='<div class="card">';
                  html +='<div class="card-content">';
                }
                html +='<div id="model_summary_summary" class="col-xs-6"></div>';

                html +='<div class="block-title block-title-medium">';
                  html +='<div id="model_summary_message" class="col-xs-6"></div>';
                html +='</div>';

                if (isApp) {
                  html +='</div>';
                  html +='</div>';
                }

              html +='</div>';

              if (isApp) {
                html +='<div class="card">';
                html +='<div class="card-content">';
              }
              html +='<div id="model_chart"></div>';
              if (isApp) {
                html +='</div>';
                html +='</div>';
              }

              html +='<div id="model_table"></div>';
            }
            else if(model_output.result_summary.last_record.phase_corrected==20) {

              if (!isApp) {
                html +='<div id="model_chart" class="row">';

                  html+='<div class="row">';
                    html +='<div id="model_chart_chart" class="col-xs-10"></div>';
                    html +='<div id="model_chart_message" class="col-xs-2"></div>';
                  html+='</div>';

                  html+='<hr>';

                  html+='<div class="row">';
                    html +='<div id="model_chart_phenology" class="col-xs-10"></div>';
                    html +='<div id="model_chart_message_phenology" class="col-xs-2"></div>';
                  html +='</div>';

                  html+='<hr>';

                  html+='<div class="row">';
                    html +='<div id="model_chart_mortality" class="col-xs-10"></div>';
                    html +='<div id="model_chart_message_mortality" class="col-xs-2"></div>';
                  html+='</div>';

                html +='</div>';
                html +='<div id="model_chart_message" class="col-xs-12"></div>';

              }
              else {

                html +='<div id="model_chart">';

                  html +='<div class="card">';
                    html +='<div class="card-content">';
                      html +='<div id="model_chart_chart" class="col-xs-10"></div>';
                      html +='<div id="model_chart_message" class="app_message col-xs-2"></div>';
                    html+='</div>';
                  html+='</div>';

                  html +='<div class="card">';
                    html +='<div class="card-content">';
                      html +='<div id="model_chart_mortality" class="col-xs-10"></div>';
                      html +='<div id="model_chart_message_mortality" class="app_message col-xs-2"></div>';
                    html+='</div>';
                  html+='</div>';

                  html +='<div class="card">';
                    html +='<div class="card-content">';
                      html +='<div id="model_chart_phenology" class="col-xs-10"></div>';
                      html +='<div id="model_chart_message_phenology" class="app_message col-xs-2"></div>';
                    html +='</div>';
                  html +='</div>';

                html +='</div>';
              }

            }
            else if(model_output.result_summary.last_record.phase_corrected==30) {

              if (!isApp) {
                html +='<div id="model_chart" class="row">';

                  html+='<div class="row">';
                    html +='<div id="model_chart_mortality" class="col-xs-10"></div>';
                    html +='<div id="model_chart_message_mortality" class="col-xs-2"></div>';
                  html+='</div>';

                  html+='<hr>';

                  html+='<div class="row">';
                    html +='<div id="model_chart_phenology" class="col-xs-10"></div>';
                    html +='<div id="model_chart_message_phenology" class="col-xs-2"></div>';
                  html +='</div>';

                  html+='<hr>';

                  html+='<div class="row">';
                    html +='<div id="model_chart_chart" class="col-xs-10"></div>';
                    html +='<div id="model_chart_message" class="col-xs-2"></div>';
                  html+='</div>';

                html +='</div>';
                html +='<div id="model_chart_message" class="col-xs-12"></div>';

              }
              else {

                html +='<div id="model_chart">';

                  html +='<div class="card">';
                    html +='<div class="card-content">';
                      html +='<div id="model_chart_mortality" class="col-xs-10"></div>';
                      html +='<div id="model_chart_message_mortality" class="app_message col-xs-2"></div>';
                    html+='</div>';
                  html+='</div>';

                  html +='<div class="card">';
                    html +='<div class="card-content">';
                      html +='<div id="model_chart_phenology" class="col-xs-10"></div>';
                      html +='<div id="model_chart_message_phenology" class="app_message col-xs-2"></div>';
                    html +='</div>';
                  html +='</div>';

                  html +='<div class="card">';
                    html +='<div class="card-content">';
                      html +='<div id="model_chart_chart" class="col-xs-10"></div>';
                      html +='<div id="model_chart_message" class="app_message col-xs-2"></div>';
                    html+='</div>';
                  html+='</div>';

                html +='</div>';
              }

            }
            else {
              html+="C'è un errore... model_output.result_summary.last_record.phase_corrected non è come mi aspettavo";
            }

            jQuery('#aedita_model').html(html);


            if (model_output.result_summary.last_record.phase_corrected==10) {
              aiSummary('model_summary_summary',model_output);
              drawAiChart('model_chart',model_output);
              var ovi_risk='';
              var risk_class='';

              var value=model_output.result_summary.last_record.p_corrected*100;

              if (value<=37) {
                ovi_risk+='basso';
                risk_class='success';
              }
              else if (value>37 && value<=50) {
                ovi_risk+='medio';
                risk_class='warning';
              }
              else {
                ovi_risk+='alto';
                risk_class='danger';
              }

              var margin_top='90px';
              if (isApp) {
                margin_top='0';
              }

              var manual_date='';
              // debugger
              var s=null;

              if (!isApp) {
                // console.log(anno);
                // debugger

                s=jQuery.jStorage.get('ai_'+anno+'_'+id_station);
              }
              else {
                s=$.jStorage.get('ai_app');
              }

              console.log("KLEAN",s);
              if (s && s.data_ovideposizione) {
                manual_date+='<div id="manual_date" style="margin-top:20px;>';
                  manual_date+='<p style="text-align:center;"><label for="data_ovideposizioni">Modifica la data di inizio delle ovideposizioni</label></p>';
                  manual_date+='<p style="text-align:center;"><input class="app_date form-control" type="date" id="data_ovideposizioni" value="'+model_output.result_summary.date_start+'"></input></p>';
                manual_date+='</div>';
              }


              var check_data_meteo='';
              if (weather_data[weather_data.length-1].datetime>=currentYear+'-10-15') {
                // check_data_meteo='<hr><p style="font-weight: 100;"> Il ciclo dei dati meteo è concluso </p>';
              }


              jQuery('#model_summary_message').html('<div style="margin-top: '+margin_top+'; font-weight: bold;" class="ovideposizione_risk alert alert-'+risk_class+'">Il rischio di ovideposizione è '+ovi_risk+'.  '+check_data_meteo+' </div>'+manual_date);

              changeData(id_station,anno);
            }
            else {
              drawAiChart('model_chart_chart',model_output);
              ovideposizioneMessage(model_output,id_station,anno);

              drawMortalityChart('model_chart_mortality',model_output);
              mortalityMessage(model_output,id_station);

              drawPhenologyHeatmap('model_chart_phenology',model_output);
              phenologyMessage(model_output,id_station);

            }


          }
          else {
            var html='';
            html+=`
            <div class="block block-strong inset">
              <p>Il DSS sulla difesa dalla mosca dell'olivo si attiva da Giugno</p>
            </div>`;
            jQuery('#aedita_model').html(html);
          }
        }
      });

    }
  });
}

function mortalityMessage(model_output,id_station){


  var riskClass='info';
  var riskTitle='---';

  var sum_mortality_weekly=model_output.result_summary.last_record.mortality_cum7d;

  if (sum_mortality_weekly>0.5) {
    riskClass='success';
    riskTitle='Rischio basso';
  }
  else if (sum_mortality_weekly<=0.5 && sum_mortality_weekly>0.1) {
    riskClass='warning';
    riskTitle='Rischio medio';
  }
  else if (sum_mortality_weekly<=0.1) {
    riskClass='danger';
    riskTitle='Rischio alto';
  }


  var message='<div class="alert alert-'+riskClass+'">';
    console.log(model_output);
    message+='<b>'+riskTitle+'</b></br>';
    message+='Il modello prevede che la mortalità giornaliera delle forme giovanili di mosca dell’olivo è del <b>'+(model_output.result_summary.last_record.mortality*100).toFixed(0)+'%</b>, la mortalità cumulata settimanale è del <b>'+(sum_mortality_weekly*100).toFixed(0)+'%</b>.';
  message+='</div>';

  if (!isApp) {
    message+='<button id="showTableMortality" class="btn btn-default btn-block">Vedi tabella</button>';
  }

  jQuery('#model_chart_message_mortality').html(message);

  jQuery('#showTableMortality').click(function(){
    showTableMortality(model_output);
  });
}

function showTableMortality(model_output){
  console.log(model_output);
  var table='';
  table+='<table class="table">';
    table+='<thead>';
      table+='<th>Data</th>';
      table+='<th>Mortalità giornaliera</th>';
      table+='<th>Mortalità settimanale</th>';
      table+='<th>Rischio</th>';
    table+='</thead>';

    table+='<tbody>';
    jQuery.each(model_output.results.sort(function(a,b){
      return new Date(b.date) - new Date(a.date);
    }),function(k,v){
        table+='<tr>';
          table+='<td>'+v.date+'</td>';
          table+='<td>'+((v.mortality*100).toFixed(0))+'%</td>';
          table+='<td>'+(v.mortality_cum7d*100).toFixed(0)+'%</td>';
          var risk='';

          if (v.mortality_cum7d>0.5) {
            risk='Rischio basso';
          }
          else if (v.mortality_cum7d<=0.5 && v.mortality_cum7d>0.1) {
            risk='Rischio medio';
          }
          else if (v.mortality_cum7d<=0.1) {
            risk='Rischio alto';
          }

          table+='<td>'+risk+'</td>';
        table+='</tr>';
      });
    table+='</tbody>';
  table+='</table>';

  jQuery('.modal-body').html(table);
  jQuery('.modal').modal('show');
}

function phenologyMessage(model_output,id_station){

    var reached_stage='';

    jQuery.each(model_output.results.values,function(k,v){
      if (v.pheno_fly) {
        if (v.pheno_fly.eggs>0) {
          reached_stage='uovo';
        }
        if (v.pheno_fly.larva>0) {
          reached_stage='larva';
        }
        if (v.pheno_fly.pupa>0) {
          reached_stage='pupa';
        }
        if (v.pheno_fly.adult>0) {
          reached_stage='adulto';
        }
      }
    });



  var message='<div class="alert alert-default">';
    message+='Considerando l’inizio delle ovideposizioni al '+model_output.result_summary.date_start+', il modello previsionale stima che la fase raggiunta è <b>'+reached_stage+'</b>.';
  message+='</div>';

  if (!isApp) {
    message+='<button id="showTablePheno" class="btn btn-default btn-block">Vedi tabella</button>';
  }

  jQuery('#model_chart_message_phenology').html(message);

  jQuery('#showTablePheno').click(function(){
    showTablePheno(model_output);
  });
}

function showTablePheno(model_output){
  console.log(model_output);

  console.log(model_output);
  var table='';
  table+='<table class="table">';
    table+='<thead>';
      table+='<th>Data</th>';
      table+='<th>Uovo</th>';
      table+='<th>Larva</th>';
      table+='<th>Pupa</th>';
      table+='<th>Adulto</th>';
    table+='</thead>';

    table+='<tbody>';

      jQuery.each(model_output.results.sort(function(a,b){
        return new Date(b.date) - new Date(a.date);
      }),function(k,v){
        if (v.pheno_fly) {
          table+='<tr>';
            table+='<td>'+v.date+'</td>';
            table+='<td>'+(v.pheno_fly.eggs).toFixed(0)+'%</td>';
            table+='<td>'+(v.pheno_fly.larva).toFixed(0)+'%</td>';
            table+='<td>'+(v.pheno_fly.pupa).toFixed(0)+'%</td>';
            table+='<td>'+(v.pheno_fly.adult).toFixed(0)+'%</td>';
          table+='</tr>';
        }
      });
    table+='</tbody>';
  table+='</table>';

  jQuery('.modal-body').html(table);
  jQuery('.modal').modal('show');
}

function ovideposizioneMessage(model_output,id_station,anno,phase){

  var message='';
  if (typeof phase=='undefined') {
    var riskClass='info';
    var riskTitle='---';

    var p_corrected=model_output.result_summary.last_record.p_corrected;

    if (p_corrected>0.5) {
      riskClass='danger';
      riskTitle='Rischio alto';
    }
    else if (p_corrected<=0.5 && p_corrected>0.37) {
      riskClass='warning';
      riskTitle='Rischio medio';
    }
    else if (p_corrected<=0.37) {
      riskClass='success';
      riskTitle='Rischio basso';
    }

    message='<div class="alert alert-'+riskClass+'">';
    message+='<b>'+riskTitle+'</b></br>';

  }
  else {
    message='<div class="alert alert-default">';

  }

    if (model_output.result_summary.date_start_model!=null) {
      message+='Il modello prevede che le ovideposizioni sono partite il '+model_output.result_summary.date_start_model+'.';
    }
    else {
      message+='Il modello prevede che le ovideposizioni non sono ancora partite.';
    }

    message+='<div id="manual_date" style="margin-top:20px;>';
      message+='<p style="text-align:center;"><label for="data_ovideposizioni">Modifica la data di inizio delle ovideposizioni</label></p>';
      message+='<p style="text-align:center;"><input class="app_date form-control" type="date" id="data_ovideposizioni" value="'+model_output.result_summary.date_start+'"></input></p>';
    message+='</div>';

  message+='</div>';

  if (!isApp) {
    message+='<button id="showTableOvi" class="btn btn-default btn-block">Vedi tabella</button>';
  }


  jQuery('#model_chart_message').html(message);

  jQuery('#showTableOvi').click(function(){
    showTableOvi(model_output);
  });

  changeData(id_station,anno);
}

function changeData(id_station,anno){
  jQuery('#data_ovideposizioni').change(function(){
    // debugger
    var settings={};
    var s;

    if (!isApp) {
      s=jQuery.jStorage.get('ai_'+anno+'_'+id_station);
    }
    else {
      s=$.jStorage.get('ai_app');
    }

    if(typeof s !==undefined && s!==null){
      settings=s;
    }
    settings.data_ovideposizione=jQuery(this).val();

    if (!isApp) {
      jQuery.jStorage.set('ai_'+anno+'_'+id_station,settings);
    }
    else {
      $.jStorage.set('ai_app',settings);
    }


    console.log("id_station:"+id_station);
    aiModel(id_station,anno);
  });
}

function showTableOvi(model_output){
  console.log(model_output);
  var table='';
  table+='<table class="table">';
    table+='<thead>';
      table+='<th>Data</th>';
      table+='<th>Probabilità di ovideposizione</th>';
      table+='<th>Rischio</th>';
    table+='</thead>';

    table+='<tbody>';

    jQuery.each(model_output.results.sort(function(a,b){
      return new Date(b.date) - new Date(a.date);
    }),function(k,v){
        table+='<tr>';
          table+='<td>'+v.date+'</td>';
          table+='<td>'+(v.p_corrected*100).toFixed(0)+'%</td>';
          var risk='';
          if (v.p_corrected>0.5) {
            risk='Rischio alto';
          }
          else if (v.p_corrected<=0.5 && v.p_corrected>0.37) {
            risk='Rischio medio';
          }
          else if (v.p_corrected<=0.37) {
            risk='Rischio basso';
          }

          table+='<td>'+risk+'</td>';
        table+='</tr>';
      });
    table+='</tbody>';
  table+='</table>';

  jQuery('.modal-body').html(table);
  jQuery('.modal').modal('show');
}

function drawPhenologyHeatmap(div,model_output){


  var categories=[];

  var adult=[];
  var eggs=[];
  var larva=[];
  var pupa=[];

  jQuery.each(model_output.results.values,function(k,v){
    if (v.pheno_fly) {
      eggs.push(v.pheno_fly.eggs);
      larva.push(v.pheno_fly.larva);
      pupa.push(v.pheno_fly.pupa);
      adult.push(v.pheno_fly.adult);
      categories.push(v.date);
    }
  });

  var series=[{
    name:"Adulto",
    data:adult
  },{
    name:"Pupa",
    data:pupa
  },{
    name:"Larva",
    data:larva
  },{
    name:"Uovo",
    data:eggs
  }];

  console.log(series);
  console.log(categories);

  var chartHeight=350;
  if (isApp) {
    chartHeight=200;
  }

  var options = {
    series: series,
    chart: {
      toolbar:{
        show:!isApp
      },
      height: chartHeight,
      type: 'heatmap',
    },
    dataLabels: {
      enabled: false
    },
    // colors: ["#f44336","#ff9100","#fdd835","#43a047"],
    colors: ["#2196f3","#2196f3","#2196f3","#2196f3"],
    xaxis: {
      type: 'category',
      categories: categories
    },
    title: {
      text: 'Generazioni teoriche della mosca dell\'olivo'
    },
    grid: {
      padding: {
        right: 20
      }
    }
  };

  // debugger
  var chart = new Apex(document.querySelector("#"+div), options);
  chart.render();
}

function drawMortalityChart(div,model_output){
  var series=[];
  var series_7d=[];
  var categories=[];

  jQuery.each(model_output.results.values,function(k,v){
    if (v.mortality!=null) {
      series.push(v.mortality*100);
      series_7d.push(v.mortality_cum7d*100);
      categories.push(v.date);
    }
  });

  var chartHeight=350;
  if (isApp) {
    chartHeight=200;
  }
   var options = {
     series: [{
       name: "Mortalità giornaliera",
       data: series
      },
      {
        name: "Mortalità settimanale",
        data: series_7d
      }],
     chart: {
       toolbar:{
         show:!isApp
       },
       height: chartHeight,
       type: 'line',
       zoom: {
         enabled: false
       }
     },
     dataLabels: {
       enabled: false
     },
     colors: ['#f44336','#ffc107'],
     stroke: {
       curve: 'smooth'
     },
     title: {
        text: 'Mortalità delle forme giovanili di mosca dell\'olivo',
        align: 'left'
      },
     yaxis: {
        // min: 0,
        // max: 100,
        labels: {
          formatter: function (value) {
            return value.toFixed(0) + "%";
          }
        },
      },
     xaxis: {
       categories: categories
     }
   };

   var chart = new Apex(document.querySelector('#'+div), options);
   chart.render();

}

function drawAiChart(div, model_output){
  var series=[];
  var categories=[];

  jQuery.each(model_output.results.values,function(k,v){
    series.push(v.p_corrected*100);
    categories.push(v.date);
  });


  var chartHeight=350;
  if (isApp) {
    chartHeight=200;
  }
   var options = {
     series: [{
       name: "Probabilità di ovideposizione",
       data: series
     }],
     chart: {
       toolbar:{
         show:!isApp
       },
       height: chartHeight,
       type: 'line',
       zoom: {
         enabled: false
       }
     },
     annotations: {
       yaxis: [{
         y: 0,
         y2: 37,
         borderColor: '#9FBE35',
         fillColor: '#9FBE35',
         opacity: 0.2,
       },{
         y: 37,
         y2: 50,
         borderColor: '#F1BA3A',
         fillColor: '#F1BA3A',
         opacity: 0.2
       },
       {
         y: 50,
         y2: 100,
         borderColor: '#F33133',
         fillColor: '#F33133',
         opacity: 0.2
       }]
     },
     dataLabels: {
       enabled: false
     },
     stroke: {
       curve: 'straight'
     },
     title: {
        text: 'Probabilità di inizio delle ovideposizioni di mosca dell\'olivo',
        align: 'left'
      },
     yaxis: {
       tickAmount:5,
        min: 0,
        max: 100,
        labels: {
          formatter: function (value) {
            return value.toFixed(0) + "%";
          }
        },
      },
     xaxis: {
       categories: categories
     }
   };

   var chart = new Apex(document.querySelector('#'+div), options);
   chart.render();

}

export function aiSummary(div,model_output){

  var value=model_output.result_summary.last_record.p_corrected;

  console.log(value);

  jQuery('script[src="https://cdnjs.cloudflare.com/ajax/libs/d3/3.5.4/d3.min.js"]').remove();

  jQuery.getScript( "https://cdnjs.cloudflare.com/ajax/libs/d3/4.4.1/d3.min.js", function(){
    drawGauge('#'+div,value*100);
  });

}

export function drawGauge(div,value){
  /*---------------------
  // D3.js Gauge Chart //
  ---------------------*/
  // Data which need to be fetched
  var name = '';
  // var name = value.toFixed(0)+'% di infestazione';
  var alert='';
  if (value<=37) {
    alert+='Basso rischio';
  }
  else if (value>37 && value<=50) {
    alert+='Allerta';
  }
  else {
    alert+='Alto rischio';
  }
  // value = 75;			    // My Desired Value To Show
  var gaugeMaxValue = 100;

  // Data of calculation
  var percentValue = value / gaugeMaxValue;
  var needleClient;
  (function () {
  	var barWidth, chart, chartInset, degToRad, repaintGauge, height, margin, numSections, padRad, percToDeg, percToRad, percent, radius, sectionIndx, svg, totalPercent, width;

  	percent = percentValue;

  	numSections = 1;
  	var sectionPerc = 1 / numSections / 2;
  	padRad = 0.025;
  	chartInset = 10;

  	// Orientation of Gauge:
  	totalPercent = 0.75;

    var el = d3.select(div);

  	margin = {
  		top: 20,
  		right: 20,
  		bottom: 20,
  		left: 20
  	};

  	width = jQuery(div).width()-30;
  	height = width;
  	radius = Math.min(width, height) / 2;
  	barWidth = 40 * width / 300;

  	// Utility methods
  	percToDeg = function (perc) {
  		return perc * 360;
  	};

  	percToRad = function (perc) {
  		return degToRad(percToDeg(perc));
  	};

  	degToRad = function (deg) {
  		return deg * Math.PI / 180;
  	};

  	// Create SVG element
  	svg = el.append('svg').attr('width', width + margin.left + margin.right).attr('height', height / 1.5 + margin.top + margin.bottom);		// height/1.5 To Remove Extra Bottom Space

  	// Add layer for the panel
  	chart = svg.append('g').attr('transform', "translate(" + ((width + margin.left) / 2) + ", " + ((height + margin.top) / 2) + ")");

  	chart.append('path').attr('class', "arc chart-first");
  	chart.append('path').attr('class', "arc chart-second");
  	chart.append('path').attr('class', "arc chart-third");

  	var arc3 = d3.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth)
  	var arc2 = d3.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth)
  	var arc1 = d3.arc().outerRadius(radius - chartInset).innerRadius(radius - chartInset - barWidth)

  	repaintGauge = function () {
  		var perc = 0.5;
  		var next_start = totalPercent;
      // debugger
  		var arcStartRad = percToRad(next_start);
  		var arcEndRad = arcStartRad + percToRad(perc *.37);
  		next_start += perc * .37;

  		arc1.startAngle(arcStartRad).endAngle(arcEndRad);

  		arcStartRad = percToRad(next_start);
  		arcEndRad = arcStartRad + percToRad(perc *.13);
  		next_start += perc* .13;

  		arc2.startAngle(arcStartRad + padRad).endAngle(arcEndRad);

  		arcStartRad = percToRad(next_start);
  		arcEndRad = arcStartRad + percToRad(perc *.5);

  		arc3.startAngle(arcStartRad + padRad).endAngle(arcEndRad);

  		chart.select(".chart-first").attr('d', arc1);
  		chart.select(".chart-second").attr('d', arc2);
  		chart.select(".chart-third").attr('d', arc3);

  	}

  	var Needle = (function () {

  		//Helper function that returns the `d` value for moving the needle
  		var recalcPointerPos = function (perc) {
        // debugger
  			var centerX, centerY, leftX, leftY, rightX, rightY, thetaRad, topX, topY;
  			thetaRad = percToRad(perc / 2);
  			centerX = 0;
  			centerY = 0;
  			topX = centerX - this.len * Math.cos(thetaRad);
  			topY = centerY - this.len * Math.sin(thetaRad);
  			leftX = centerX - this.radius * Math.cos(thetaRad - Math.PI / 2);
  			leftY = centerY - this.radius * Math.sin(thetaRad - Math.PI / 2);
  			rightX = centerX - this.radius * Math.cos(thetaRad + Math.PI / 2);
  			rightY = centerY - this.radius * Math.sin(thetaRad + Math.PI / 2);
  			return "M " + leftX + " " + leftY + " L " + topX + " " + topY + " L " + rightX + " " + rightY;
  		};

  		function Needle(el) {
  			this.el = el;
  			this.len = width / 2.5;
  			this.radius = this.len / 8;
  		}

  		Needle.prototype.render = function () {
  			this.el.append('circle').attr('class', 'needle-center').attr('cx', 0).attr('cy', 0).attr('r', this.radius);
  			return this.el.append('path').attr('class', 'needle').attr('id', 'client-needle').attr('d', recalcPointerPos.call(this, 0));
  		};

  		Needle.prototype.moveTo = function (perc) {
  			var self,oldValue = this.perc || 0;
  			this.perc = perc;
  			self = this;

  			// // // Reset pointer position
  			this.el.transition().delay(100).ease(d3.easeQuad).duration(200).select('.needle').tween('reset-progress', function () {
  				return function (percentOfPercent) {
  					var progress = (1 - percentOfPercent) * oldValue;
  					repaintGauge(progress);

  					// return d3.select(this).attr('d', recalcPointerPos.call(self, progress));
            return jQuery(div+' .needle').attr('d', recalcPointerPos.call(self, progress));
  				};
  			});

  			this.el.transition().delay(300).ease(d3.easeBounce).duration(1500).select('.needle').tween('progress', function () {
  				return function (percentOfPercent) {
  					var progress = percentOfPercent * perc;
            repaintGauge(progress);

            // debugger

  					return jQuery(div+' .needle').attr('d', recalcPointerPos.call(self, progress));
  				};
  			});

  		};


  		return Needle;

  	})();

  	var dataset = [{
  		metric: name,
  		value: value
  	}]

  	var texts = svg.selectAll("text")
  		.data(dataset)
  		.enter();

  	texts.append("text")
  		.text(function () {
  			return dataset[0].metric;
  		})
      .attr('id', "Name")
  		.attr('text-anchor', "middle")
  		.attr('transform', "translate(" + ((width) / 2) + ", " + ((height + margin.top) / 1.6) + ")")
  		.attr("font-size", 25)
  		.style("fill", "#000000");

    texts.append("text")
  		.text(function () {
  			return alert;
  		})
      .attr('id', "Alert")
  		.attr('text-anchor', "middle")
  		.attr('transform', "translate(" + ((width) / 2) + ", " + (((height + margin.top) / 1.6)+5) + ")")
  		.attr("font-size", 25)
  		.style("fill", "#000000");



  	var trX = 180 - 210 * Math.cos(percToRad(percent / 2));
  	var trY = 195 - 210 * Math.sin(percToRad(percent / 2));
  	// (180, 195) are the coordinates of the center of the gauge.

  	// texts.append("text")
  	// 	.text(function () {
  	// 		return 0;
  	// 	})
  	// 	.attr('id', 'scale0')
  	// 	.attr('transform', "translate(" + ((0)) + ", " + ((height) / 2) + ")")
  	// 	.attr("font-size", 25)
  	// 	.style("fill", "#000000");
    //
    // texts.append("text")
  	// 	.text(function () {
  	// 		return '37';
  	// 	})
  	// 	.attr('id', 'scale05')
  	// 	.attr('transform', "translate(" + ((width) / 3.3) + ", " + 30 + ")")
  	// 	.attr("font-size", 25)
  	// 	.style("fill", "#000000");
    //
    //
    //
  	// texts.append("text")
  	// 	.text(function () {
  	// 		return gaugeMaxValue / 2;
  	// 	})
  	// 	.attr('id', 'scale10')
  	// 	.attr('transform', "translate(" + ((width) / 2) + ", " + 19 + ")")
  	// 	.attr("font-size", 25)
  	// 	.style("fill", "#000000");
    //
    //
  	// texts.append("text")
  	// 	.text(function () {
  	// 		return gaugeMaxValue;
  	// 	})
  	// 	.attr('id', 'scale20')
  	// 	.attr('transform', "translate(" + ((width)+1) + ", " + ((height + margin.top) / 2) + ")")
  	// 	.attr("font-size", 25)
  	// 	.style("fill", "#000000");

  	var needle = new Needle(chart);
  	needle.render();
    console.log(percent);
  	needle.moveTo(percent);

  })();
}
