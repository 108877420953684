/*jshint esversion: 6 */
import global from "../../global.js";
import gps from '../../core/gps.js';
import * as ai from './ai.js';
import ApexCharts from 'apexcharts';

// console.log(ApexCharts);
class model {
  constructor() {
    // INSERIRE LA BASE_CALL NEL RUN DEL PROGETTO
  }

  modello(){
    var id_station=null;
    var lat=43;
    var lon=11;



    var html='';

    html+="<div id='aedita_model'></div>";

    var content={'title': '', 'content': html, exclude_card:true};

    global.theme.render(content);

    var isApp=true;
    ai.aiModel(id_station,new Date().getFullYear(),lat,lon,global.base_path, ApexCharts,isApp);
  }

  old_modello(){
    console.log("ciao");
    var urladdr = global.base_path+'monitoring_webpack/'+"app_model";
    jQuery.ajax({
      type: "POST",
      url: urladdr,
      dataType: "json",
      success: function(data){
        console.log(data);

        var dato=data.data[0];

        console.log("Ciao");

        var html='';

        html+="<div id='minimap'></div>";

        html+='<div class="row"><span id="place"></span><span id="date"></span></div>';
        html+='<div id="alert-message" class="alert alert-'+dato.alert_level+'">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</div>';
        html+='<div class="text-align-center">';
          html+='<div id="model-gauge"class="gauge model-gauge"></div>';
        html+='</div>';



        var content={'title': '', 'content': html, exclude_card:false};
        global.theme.render(content);


        global.map = L.map('minimap');
        var base=L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        }).addTo(global.map);

        global.map.setView([dato.lat, dato.lon], 16);
        global.marker = L.marker([dato.lat, dato.lon]).addTo(global.map);

        gps.reverseGeocode(dato);
        jQuery('#date').html("Data: "+dato.date);

        setTimeout(function(){
          console.log(global.coordinates);
          jQuery('#place').html("Luogo: "+global.coordinates.place);
        },1000);

        console.log(parseFloat(dato.prob_presence));

        var modelGauge = global.app.gauge.create({
          el: '#model-gauge',
          type: 'semicircle',
          value: parseFloat(dato.prob_presence),
          size: 250,
          borderColor: '#2196f3',
          borderWidth: 10,
          valueText: (parseFloat(dato.prob_presence)*100).toFixed(1)+'%',
          valueFontSize: 41,
          valueTextColor: '#2196f3',
          labelText: 'amount of something',
        });

      },
      error: function(e){
        console.log(e);
      },
    });
  }

}

export default (new model());
