/*jshint esversion: 6 */

// Qui ci sono le variabili globali

class global {
  constructor() {
    // INSERIRE LA BASE_CALL NEL RUN DEL PROGETTO
  }

  is_cordova() {
    return (typeof(cordova) !== 'undefined' || typeof(phonegap) !== 'undefined');
  }

  hashCode (str){
      var hash = 0;
      if (str.length == 0) return hash;
      for (var i = 0; i < str.length; i++) {
          var char = str.charCodeAt(i);
          hash = ((hash<<5)-hash)+char;
          hash = hash & hash; // Convert to 32bit integer
      }
      return hash;
  }

  iOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
  }

  getTodayDate(format){
    var self=this;

    var today=new Date();

    var today_yymmdd=new Date(today).getFullYear()+"-"+self.addZero(new Date(today).getMonth()+1)+"-"+self.addZero(new Date(today).getDate());
    today=self.addZero(new Date(today).getDate())+"/"+self.addZero(new Date(today).getMonth()+1)+"/"+self.addZero(new Date(today).getFullYear());
    if (typeof format!='undefined') {
      if (format=='dd/mm/yyyy') {
        return today;
      }
      else if (format=='yyyy-mm-dd') {
        return today_yymmdd;
      }
      else {
        alert('Formato non ancora sviluppato.');
        return today_yymmdd;
      }
    }
    else {
      return today_yymmdd;
    }
  }

  addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
  }

  getFileContentAsBase64(path,callback){
    var self=this;


    if(self.is_cordova()){
      window.resolveLocalFileSystemURL(path, gotFile, fail);
    }
    else{
      window.webkitResolveLocalFileSystemURL(path, gotFile, fail);
    }

    function fail(e) {
      console.log("ERROR","Cannot found requested file");
    }

    function gotFile(fileEntry) {
      fileEntry.file(function(file) {
        var reader = new FileReader();
        reader.onloadend = function(e) {
          var content = this.result;
          callback(content);
        };
        // The most important point, use the readAsDatURL Method from the file plugin
        reader.readAsDataURL(file);
      });
    }
  }


  capitalizeFirstLetter(string) {
    if (!string) {
      return string;
    }
    string=string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  createMap(div,point,zoom){
    var self=this;
    if (typeof zoom=='undefined') {
      zoom=10;
    }

    if (typeof point=='undefined') {
      point={latitude:42.5,longitude:14};
    }
    // debugger
    if(self.map){
      self.map.remove();
    }

    self.map = L.map(div).setView([point.latitude,point.longitude], zoom);
    var base=L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(self.map);

    var Esri_WorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
      attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
    });

    var baseMaps = {
      "Base": base,
      "Satellite": Esri_WorldImagery,
    };
    L.control.layers(baseMaps,{}).addTo(self.map);
  }

  crash_log(e,where){
    console.log(e);

    if (global.crashlytics) {
      global.crashlytics.log(e.toString());
      global.crashlytics.logException(where+e.toString());
    }
  }
}

export default (new global());
