/*jshint esversion: 6 */

import global from "../global.js";
import storage from "../storage.js";
import t from '../translate.js';

class Notification {
  default_notify(){
    this.onMessageReceived(function(nn){
      setTimeout(function(){
        console.log("MESSAGGIO DI NOTIFICA", nn);
        var s=storage.get();

        try {
          if (typeof s.notifications_received=='undefined') {
            s.notifications_received=[];
          }
          s.notifications_received.push(nn);

          storage.save(s);
        }
        catch (e) {
          console.log(e);
        }

        if (nn.tap && nn.tap=='background') {
          location.hash=nn.hash;
        }
        else {
          var notificationFull = global.app.notification.create({
            icon: nn.icon,
            title: nn.title,
            titleRightText: 'now',
            subtitle: '',
            text: nn.body,
            closeOnClick: true,
            closeTimeout: 3600,
            on: {
              close: function () {
                if (nn.click_action) {
                  document.location.href =nn.click_action;                  
                }
                else {
                  location.hash=nn.hash;
                }
              }
            }
          }).open();
        }
      }, 1000);
    });
  }

  onMessageReceived(notification_function){

    if(global.is_cordova()){
      if( window.FirebasePlugin ) {
        console.log("FIREBASE ESISTENTE");
        window.FirebasePlugin.onMessageReceived(function(notification) {
            console.log(notification);
            notification_function(notification);
          },
          function(error) {
            console.error(error);
        });
      }
      else {
        console.warn("Plugin not available");
      }
    }
    else {
      // debugger
      console.log('global.messaging',global.messaging);
      console.log('firebase.messaging',firebase.messaging);
      if (global.messaging && firebase.messaging.isSupported()){
        global.messaging.onMessage(notification => {
          console.log("Message received. ", notification);
          if (typeof notification=='string') {
            notification=JSON.parse(notification.data.notification);
          }
          else {
            notification=notification.notification;
          }

          notification_function(notification);
        }, e=>{
          console.log(e);
        });
      }

    }
  }

  getToken(fnc_success, fnc_error) {
    if(global.is_cordova()){
      if( window.FirebasePlugin ) {
        window.FirebasePlugin.getToken(fnc_success, function(error){
          fnc_error(error);
          console.error(error);
        });
      }
      else {
        fnc_error("Plugin not available");
        console.warn("Plugin not available");
      }
    }
    else {
      // TODO : serve prendere token per inviare notifica.
      // window.Notification invia a tutti i device (browser) che accettano le notifiche
      console.log("Verifico se window.Notification...");
      if( window.Notification ) {
        console.log("entro in window.Notification...");
        window.Notification.requestPermission(function(res){
          // alert("cccc"+res)


          console.log("Res notification:",res);
          if( res == 'denied' ) {
            fnc_error('Controlla di aver attivato le notifiche per questa app.');
          }
          else {
            console.log("Res non è denied");

            console.log("global messaging",global.messaging);
            console.log("firebase.messaging.isSupported",firebase.messaging.isSupported());

            if (global.messaging && firebase.messaging.isSupported()){
              console.log(global.messaging);

              global.messaging
              .requestPermission()
              .then(function () {
                console.log("Notification permission granted.");
                console.log("Returning new token:",global.messaging.getToken());
                return global.messaging.getToken();
              })
              .then(function(token) {
                console.log("Token:",token);
                fnc_success(token);
              })
              .catch(function (err) {
                console.log("Error granting token..");
                fnc_success('0000000-000000-1111-0000');
                console.log("Unable to get permission to notify.", err);
              });
            }

          }
        });
      }
      else {
        console.log(" window.Notification risulta FAlse.. sono nell'else!");

        // fnc_error("This browser does not support system notifications");
        // console.warn("This browser does not support system notifications");
      }
    }
  }

  notifica_home(){
    console.log("OK Notifica" );

    this.getToken(function(token){
      console.log("TOKEN Notifica");
      console.log(token);
      var html='';

      var s=storage.get();
      if (typeof s.notifications_received!='undefined') {
        if (s.notifications_received.length>0) {
          html+='<h3>Pagina delle notifiche</h3>';
          html+='<div class="data-table">';
            html+='<table>';
              html+='<thead>';
                html+='<tr>';
                  html+='<th>'+t('Data')+'</th>';
                  html+='<th>'+t('Messaggio')+'</th>';
                html+='</tr>';
              html+='</thead>';
              html+='<tbody id="farm_list">';
              jQuery.each(s.notifications_received,function(k,v){
                var timestamp=parseInt(v.sent_time);
                if (typeof v['google.sent_time']!='undefined') {
                  timestamp=v['google.sent_time'];
                }
                var d = new Date(timestamp);
                var date=d.getDate() + '/' + (d.getMonth()+1) + '/' + d.getFullYear();
                html+='<tr>';
                  html+='<td>'+date+'</td>';
                  html+='<td>'+v.body+'</td>';
                html+='</tr>';
              });
              html+='</tbody>';
            html+='</table>';
          html+='</div>';
        }
        else {
          html+="<h3>Nessuna notifica trovata.</h3>";
        }
      }
      else {
        html+="<h3>Nessuna notifica trovata.</h3>";
      }

      var content={'title': 'Notifiche', 'content': html};
      global.theme.render(content);

    });

  }
}

export default (new Notification());
