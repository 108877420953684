/*jshint esversion: 6 */

import storage from '../../../storage.js';
import global from '../../../global.js';
import notification from '../../../core/notification.js';
import t from '../../../translate.js';
import dbmng from '../../../ext/dbmng.js';
import model from '../model.js';
// import * as partecipa from '../../../core/partecipa/partecipa.js';
import * as partecipa from '../../../core/partecipa/partecipa_new.js';

import '../style.css';
import './seggiano.css';

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
global.app_version='1.0.02';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

// global.base_call='http://10.0.1.20/cosedimoda/';
// global.base_path='http://10.0.1.20/cosedimoda/api/';
global.base_path='https://cosedimoda.aedit.it/api/';
global.base_call=global.base_path+'userplus/';
global.nomeApp='CoseDiModa';
global.download_app_path='https://cosedimoda.aedit.it/app/CoseDiModa.apk';
global.useSocialLogin=true;
global.settingsName='seggiano_settings';
global.extBaseCall="http://agromap.arsia.toscana.it/agro18/api/mod_aedita_services/";

global.firebaseConfig = {
  apiKey: "AIzaSyB48QEs9BIzsAZ5uYn0MWqKMyihmZvqay8",
  authDomain: "seggiano-b243f.firebaseapp.com",
  databaseURL: "https://seggiano-b243f.firebaseio.com",
  projectId: "seggiano-b243f",
  storageBucket: "seggiano-b243f.appspot.com",
  messagingSenderId: "307799123225",
  appId: "1:307799123225:web:20b5cf438448b0c005d188",
  measurementId: "G-K98XZSSFS4"
};

global.firebase=firebase.initializeApp(global.firebaseConfig);

global.messaging = null;

if (firebase.messaging.isSupported()){
  global.messaging = firebase.messaging();
}

if (notification) {
  global.notification=notification;
}

notification.default_notify();

//Hook obbligatorio per definire la home progetto
export function home_page(){
  partecipa.home_page();
}


export function tools(){
  partecipa.partecipa_tools();
}

//Hook obbligatorio per definire le route del progetto
export function exe_routes(router){
  router.on({
    'model': function (param) {
      model.modello();
    },
    'notifica': function (param) {
      notification.notifica_home();
    }
  });

  router=partecipa.exe_routes(router);

  return router;
}


//Hook facoltativo per aggiungere delle sidebars al tema (non tutti i temi li gestiranno)
export function get_theme_param(theme_param){
  var sidebars=[
    {'label': 'Home page', 'icon_f7':'house', 'href':'#'},
    {'label': 'Modelli', 'icon_f7':'chart_bar_circle', 'href':'#model'},
    {'label': 'Notifica', 'icon_f7':'app_badge', 'href':'#notifica'},
  ];


  theme_param.sidebars=sidebars;
  theme_param.title=global.nomeApp;

  var s=storage.get();
  if (s.user) {
    theme_param.uid=s.user.uid;
    theme_param.username=s.user.username;
  }
  theme_param.ptr=false; // attiva/disattiva pull to refresh
  return theme_param;
}
